import React, { useEffect, useState } from 'react'
import image11 from "../assets/images/image11.png"
import path30 from "../assets/images/path30.png"
import screen4 from "../assets/images/screen4.png"
import path32 from "../assets/images/path32.png"
import g3171 from "../assets/images/g3171.png"
import penguineimg from "../assets/images/penguineimg.png"
import path31 from "../assets/images/path31.png"
import dotryt from "../assets/images/dotryt.png"
import path6378 from "../assets/images/path6378.png"
import image3210 from "../assets/images/image3210.png"
import vvimg04 from "../assets/images/vvimg04.png"
import vvimg03 from "../assets/images/vvimg03.png"
import vvimg02 from "../assets/images/vvimg02.png"
import dot1 from "../assets/images/dot1.png"
import dot2 from "../assets/images/dot2.png"
import dot3 from "../assets/images/dot3.png"
import dot4 from "../assets/images/dot4.png"
import map from "../assets/images/map.png"
import { Link } from 'react-router-dom'
import ContactForm from '../components/ContactForm'

const sectionsData = [
	{ id: 'our-vision', title: 'Our Vision', class: "section-one-font" },
	{ id: 'our-offerings', title: 'Our Offerings', class: "section-two-font" },
	{ id: 'how-we-execute', title: 'How We Execute', class: "section-three-font" },
	{ id: 'our-projects', title: 'Our Projects', class: "section-four-font" },
	{ id: 'contact-us', title: 'Contact Us', class: "section-five-font" },
];

function Home() {
	const [activeSection, setActiveSection] = useState('our-vision');

	useEffect(() => {
		const handleScroll = () => {
			sectionsData.forEach(section => {
				const sectionElement = document.getElementById(section.id);
				const sectionTop = sectionElement.getBoundingClientRect().top;
				const sectionHeight = sectionElement.offsetHeight;

				// Check if the section is within the viewport
				if (sectionTop <= window.innerHeight / 2 && sectionTop + sectionHeight >= window.innerHeight / 2) {
					setActiveSection(section.id);
				}
			});
		};

		window.addEventListener('scroll', handleScroll);

		// Cleanup listener on unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);


	return (
		<div className="hsec">
			<div className="scorlpy">
				<div className="scorlpysec">
					{sectionsData?.map((item, i) => (
						<a className={`${activeSection === item.id ? `active-section ${item.class}` : ''}`} href={`#${item.id}`} ><span>{item.title}</span></a>
					))}

				</div>
			</div>
			<div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex={0}>

				{/* Our Vision */}
				<div className="cfwsec hvh100 fngrbg02" id="our-vision">
					<div className="container">
						<div className="boxsec">
							<div className="rytbox"
								data-aos="fade-right"
								data-aos-easing="linear"
								data-aos-duration="2000">
								<picture className="imgbx dsktpimg"> <img src={image3210} alt="image11" /> </picture>
							</div>
							<div className="lftbox">
								<h3 className="h3txt"
									data-aos="fade-left"
									data-aos-easing="linear"
									data-aos-duration="2000"
								>creating a <span>future-ready</span> world </h3>

								<h6 className="h6txt"
									data-aos="fade-left"
									data-aos-easing="linear"
									data-aos-duration="2500"
								>Strength in Digital Capabilities, Power in Performance</h6>

								<picture className="imgbx mblimg"

								> <img src={vvimg02} alt="vvimg02" /> </picture>


								<p className="ptxt"
									data-aos="fade-left"
									data-aos-easing="linear"
									data-aos-duration="2500"
								>We envision a world where technology seamlessly integrates into every facet of life, driving innovation and fostering growth. We strive to deliver solutions that transform industries and empower businesses to thrive in the digital age.
								</p>
								<div className="fngrbtn">
									<Link to="/our-vision"> <img src={path6378} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>
							</div>
						</div>
					</div>
					<picture className="bgshep lbgshep"> <img className="dskdotsec" src={dotryt} alt="dotryt" /> <img className="mbldotsec" src={dot4} alt="dot4" /> </picture>

				</div>

				{/* our offering */}
				<div className="cedsec hvh100 fngrbg04" id="our-offerings">
					<div className="container">
						<div className="boxsec">
							<div className="lftbox"
								data-aos="fade-right"
								data-aos-duration="1500"
							>

								<h3 className="h3txt"
									data-aos="zoom-in"
									// data-aos-anchor-placement="top-center"
									data-aos-duration="1000"
								>CORE <span>DIGITAL</span> EXPERTISE </h3>

								<h6 className="h6txt"
									data-aos="zoom-in"
									// data-aos-anchor-placement="top-center"
									data-aos-duration="1500"
								>Core Strengths, Boundless Possibilities</h6>

								<p className="ptxt"
									data-aos="zoom-in"
									// data-aos-anchor-placement="top-center"
									data-aos-duration="2000"
								>Our core aspiration as an IT company is to provide digital solutions, become a leader in technology innovation, and give exceptional value to customers.</p>
								<picture className="imgbx"> <img src={image11} alt="image11" /> </picture>
								<div className="fngrbtn">
									<Link to="/our-offering"> <img src={path30} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>

								{/* <div className="fngrbtn left-30">
									<Link to="/our-offering"> <img src={path30} alt="path30" /> <span class="toltptxt">Service Offering</span> </Link>
								</div> */}
							</div>
							<div className="rytbox"
								data-aos="zoom-in"
								data-aos-anchor-placement="top-center"
								data-aos-duration="2000"
							>
								<picture className="imgbx"> <img src={image11} alt="image11" /> </picture>
							</div>
						</div>
					</div>
					<picture className="bgshep rbgshep"> <img className="dskdotsec" src={g3171} alt="g3171" /> <img className="mbldotsec" src={dot1} alt="dot1" /> </picture>
				</div>


				{/* How we excute */}
				<div className="mowesec hvh100 fngrbg05" id="how-we-execute">
					<div className="container">
						<div className="boxsec">
							<div className="lftbox">

								<h3 className="h3txt"
									data-aos="fade-right"
									data-aos-duration="1500">Maximize the <span>opportunities</span>
									<span>with our</span> expertise
								</h3>
								<h6 className="h6txt"
									data-aos="fade-right"
									data-aos-duration="2000"
								>Excellence rooted in expertise</h6>

								<p className="ptxt"
									data-aos="fade-right"
									data-aos-duration="2500"
								>We focus on continuously innovating and developing cutting-edge technologies that revolutionize industries, improve lives and deliver exceptional, customer-focused solutions that drive client success and satisfaction.</p>
								<picture className="imgbx mblimg"> <img src={vvimg03} alt="vvimg03" /> </picture>
								<div className="fngrbtn">
									<Link to="/how-we-execute"> <img src={path31} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>
							</div>
							<div className="rytbox"
								data-aos="fade-left"
								data-aos-offset="0"
								data-aos-duration="500">
								<picture className="imgbx dsktpimg"> <img src={penguineimg} alt="penguineimg" /> </picture>
							</div>
						</div>
					</div>
					<picture className="bgshep rbgshep"> <img className="dskdotsec" src={g3171} alt="g3171" /> <img className="mbldotsec" src={dot3} alt="dot3" /> </picture>

				</div>

				{/* our project */}
				<div className="iibfsec hvh100 fngrbg01" id="our-projects">
					<div className="container">
						<div className="boxsec">
							<div className="lftbox">
								<h3 className="h3txt">
									<span
										data-aos="flip-left"
										data-aos-easing="ease-out-cubic"
										data-aos-duration="2000"
									>Invest in</span> <span>ideas for</span>
									<span>a better</span> <span
										data-aos="flip-left"
										data-aos-easing="ease-out-cubic"
										data-aos-duration="2000"
									>future</span>
								</h3>

								<h6 className="h6txt"
									data-aos="fade-right"
									data-aos-duration="2000"
								>Empowering Innovations and Driving towards excellence</h6>

								<p className="ptxt"
									data-aos="fade-right"
									data-aos-duration="2500"
								> We are a team of seasoned professionals with deep industry knowledge and technical expertise. We have a history of successful projects and satisfied clients across various industries with our dedicated support team which ensures seamless implementation and ongoing maintenance.</p>
								<div className="fngrbtn">
									<Link to="/our-project"> <img src={path32} alt="path30" /> <span class="toltptxt">Explore</span> </Link>
								</div>
							</div>
							<div className="rytbox"
								data-aos="fade-left"
							>
								<picture className="imgbx dsktpimg"> <img src={screen4} alt="screen4" /> </picture>
								<picture className="imgbx mblimg"> <img src={vvimg04} alt="vvimg04" /> </picture>
							</div>
						</div>
					</div>
					<picture className="bgshep rbgshep"> <img className="dskdotsec" src={g3171} alt="g3171" /> <img className="mbldotsec" src={dot2} alt="dot2" /> </picture>

				</div>

				<div className="gitsec hvh100 fngrbg02" id="contact-us">
					<div className="container">
						<div className="row">
							<div className="col-lg-2">
								{/* <ContactForm /> */}
							</div>
							{/* <div className="col-lg-1"></div> */}
							<div className="col-lg-8">
								<picture className="mapbox">
									<img src={map} alt="Location" width={100} style={{ height: 'auto' }} /></picture>

								<div className="adrsec row d-flex justify-content-between align-items-start mt-5">
									<div className="col-lg-3 col-sm-6 col-12">
										<div className="box">
											<h6>Email:</h6>
											<p><a href="mailto:Sales@identiqa.co.in">Sales@identiqa.co.in</a></p>
										</div>
										<div className="box">
											<h6>Linkdin:</h6>
											<p><svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
												<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
											</svg> <a href="https://www.linkedin.com/company/identiqaconsulting/" target="_blank" rel="noreferrer">identiqa</a></p>
										</div>
									</div>

									<div className="col-lg-3 col-sm-6 col-12"><div className="box">
										<h6>USA Office:</h6>
										<p className="m-0">8 The Green,
											<br />STE A Dover,
											<br />DE 19901</p>

									</div></div>

									<div className="col-lg-3 col-sm-6 col-12"><div className="box">
										<h6>New Delhi Office:</h6>
										<p className="m-0">20, Okhla Industrial Area Phase III,
											<br /> New Delhi - 110020
											<br />India</p>

									</div></div>

									<div className="col-lg-3 col-sm-6 col-12">
										<div className="box">
											<h6>Mumbai Office:</h6>
											<p className="mt-2">Bldg. No.: 12, 13 & 14 <br /> Vikhroli Corporate Park Tower, Lal Bahadur Shastri Marg, Vikhroli West Mumbai - 400079 Maharashtra, India</p>
										</div></div>

								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default Home