import 'bootstrap/dist/css/bootstrap.min.css';
// import AllRoutes from './AllRoutes';
import React, { Suspense, useEffect } from 'react';
import Home from './page/Home';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HowWeExcute from './page/HowWeExcute';
import OurOfferings from './page/OurOfferings';
import OurProject from './page/OurProject';
import OurVision from './page/OurVision';
import ScrollToTop from './components/ScrollToTop';
// import Test from './page/Test';
import PrivacyPolicy from './page/PrivacyPolicy';
import NotFound from './page/NotFound';

import AOS from 'aos';
import 'aos/dist/aos.css';
import ServiceOfferings from './page/ServiceOfferings';

const App = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <React.Fragment>
      <div id="wrapper">
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/how-we-execute" element={<HowWeExcute />} />
          <Route path="/our-offering" element={<OurOfferings />} />
          <Route path="/service-offering" element={<ServiceOfferings />} />
          <Route path="/our-project" element={<OurProject />} />
          <Route path="/our-vision" element={<OurVision />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </div>
      <Footer />
    </React.Fragment>

  )

}

export default App;
