import React from 'react'
import ooimg01 from '../assets/images/service-offering/vic1@100x.png'
import ooimg02 from '../assets/images/service-offering/vic2@100x.png'
import ooimg03 from '../assets/images/service-offering/vic3@100x.png'
import ooimg04 from '../assets/images/service-offering/vic4@100x.png'

import bkbtn from '../assets/images/bkbtn.png'
import { Link } from 'react-router-dom'
// import back_nav_icon from '../assets/images/left-arrow-back-svgrepo-com (1).svg'

function ServiceOfferings() {
	return (
		<div className="inr_sec sersec">
			<div className="container">
				<div className="boxsec">
					<div className="backnav tp">
						<Link to="/our-offering"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
					<div className="hedbox">
						<h3 className="h3txt tparwimg arwimg">SERVICE OFFERING</h3>
						<p className="ptxt">At IDENTIQA, we believe in a client centric approach that priorities understanding and addressing the specific needs of each client. We focus on continuous innovation and improvement, fostering a culture of excellence and collaboration. We are working to make a positive impact on society through technology-driven solutions and corporate social responsibility initiatives. -</p>
					</div>
					<div className="secbox"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"

					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">ARCHITECTURE WORKSHOPS</h4>
									<ul className="lisbx">
										<li>Architecture Evaluation.</li>
										<li>Architecture Design and Optimizations.</li>
									</ul>

								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox">
									<picture className="imgbox"> <img src={ooimg01} alt="ooimg01" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"

						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">CONSULTING ENGAGEMENTS</h4>
									<ul className="lisbx">
										<li>Business Requirement Workshops.</li>
										<li>GAP Analysis workshops.</li>
										<li>Technical Analysis and Recommendation.</li>
									</ul>

								</div>
							</div>
							<div className="col-md-5 order-md-1">
								<div className="lftbox">
									<picture className="imgbox"> <img src={ooimg02} alt="ooimg02" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"

						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-center"
					>
						<div className="row">
							<div className="col-md-7">
								<div className="lftbox">
									<h4 className="h4txt arwimg lftarwimg">IMPLEMENTATION ENGAGEMENT</h4>
									<ul className="lisbx">
										<li>Infrastructure Implementation.</li>
										<li>Security and Cloud Implementation.</li>
										<li>Configuration and Integration projects.</li>
										<li>Green Field and Brown Field Implementations.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-5">
								<div className="rytbox"
									data-aos="zoom-in-up"
									data-aos-duration="1000"
									data-aos-easing="ease-in-sine">
									<picture className="imgbox"> <img src={ooimg03} alt="ooimg03" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="secbox"

						data-aos="zoom-in-up"
						data-aos-duration="1000"
						data-aos-easing="ease-in-sine"
						data-aos-anchor-placement="top-bottom"
					>
						<div className="row">
							<div className="col-md-7 order-md-2">
								<div className="rytbox">
									<h4 className="h4txt arwimg rytarwimg">MANAGED SERVICES</h4>
									<ul className="lisbx">
										<li>Global Project Support Services.</li>
										<li>Audit and Assessment Services.</li>
										<li>Operations Support and Management.</li>
									</ul>
								</div>
							</div>
							<div className="col-md-5 order-md-1">
								<div className="lftbox">
									<picture className="imgbox"> <img src={ooimg04} alt="ooimg04" /> </picture>
								</div>
							</div>
						</div>
					</div>
					<div className="backnav btm mt-5">
						<Link to="/"><img src={bkbtn} alt="bkbtn" srcset="" /> </Link>
					</div>
				</div>
			</div>
		</div>

	)
}

export default ServiceOfferings